import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from 'react-bootstrap/Dropdown'
import { Link, withRouter } from 'react-router-dom';
import './header.css';
import '../../styles.css';
import mainLogo from'../../Images/vulcanLogo.png';
import "bootstrap/dist/css/bootstrap.min.css";
import SignOutButton from '../SignOut/signOut.js';
import { AuthUserContext } from '../AuthenticationSession/index.js';
import NavDropdown from "react-bootstrap/NavDropdown"
import {logEvent, aEventClick, aTypeLink, aTypeButton} from '../Analytics/analytics'
import accountIcon from'../../Images/accountIcon.png';

const aGroupHeader = "header"

class HeaderClass extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      showCoursesDropdown: false,
      showAboutDropdown: false,
      showAccountDropdown: false,
      expandedNavBar: false
  };
  }

  toggleExpandedNavBar = () => {
    this.setState({
      expandedNavBar: !this.state.expandedNavBar
    })
  }

  hideExpandedNavBar = () => {
    this.setState({
      expandedNavBar: false
    })
  }

  AccountClicked = (event) => {
    if (!this.state.expandedNavBar) {
      if (event.target.className === "accountImage") {
        this.props.history.push("/Dashboard")
        this.toggleHideAccountDropdown()
        this.hideExpandedNavBar()
      }

      if (event.target.className === "dropdown-toggle btn") {
        this.toggleShowAccountDropdown()
      }
    } else {
      this.toggleShowAccountDropdown()
    }
  }

  toggleShowAccountDropdown = () => {
    this.setState({
      showAccountDropdown: true
    })
  }

  toggleHideAccountDropdown = () => {
    this.setState({
      showAccountDropdown: false
    })
  }

  DashboardClicked = () => {
    this.toggleHideAccountDropdown()
    this.hideExpandedNavBar()
  }

  ProfileClicked = () => {
    this.toggleHideAccountDropdown()
    this.hideExpandedNavBar()
  }

  SignOutClicked = () => {
    this.toggleHideAccountDropdown()
    this.hideExpandedNavBar()
  }

  AboutClicked = (event) => {
    if (!this.state.expandedNavBar) {
      if (event.target.className === "mainNavLink") {
        this.props.history.push("/mission")
        this.toggleHideAboutDropdown()
        this.hideExpandedNavBar()
      }

      if (event.target.className === "dropdown-toggle btn") {
        this.toggleShowAboutDropdown()
      }
    } else {
      this.toggleShowAboutDropdown()
    }
  }

  toggleShowAboutDropdown = () => {
    this.setState({
      showAboutDropdown: true
    })
  }

  toggleHideAboutDropdown = () => {
    this.setState({
      showAboutDropdown: false
    })
  }

  MissionClicked = () => {
    this.toggleHideAboutDropdown()
    this.hideExpandedNavBar()
  }

  AdmissionsClicked = () => {
    this.toggleHideAboutDropdown()
    this.hideExpandedNavBar()
  }

  toggleShowCoursesDropdown = () => {
    this.setState({
      showCoursesDropdown: true
    })
  }

  toggleHideCoursesDropdown = () => {
    this.setState({
      showCoursesDropdown: false
    })
  }

  CoursesClicked = (event) => {
    if (!this.state.expandedNavBar) {
      if (event.target.className === "mainNavLink") {
        this.props.history.push("/courses")
        this.toggleHideCoursesDropdown()
        this.hideExpandedNavBar()
      }

      if (event.target.className === "dropdown-toggle btn") {
        this.toggleShowCoursesDropdown()
      }
    } else {
      this.toggleShowCoursesDropdown()
    }
  }

  AllCoursesClicked = () => {
    this.toggleHideCoursesDropdown()
    this.hideExpandedNavBar()
  }

  IntroClicked = () => {
    this.toggleHideCoursesDropdown()
    this.hideExpandedNavBar()
  }

  PythonClicked = () => {
    this.toggleHideCoursesDropdown()
    this.hideExpandedNavBar()
  }

  IOSClicked = () => {
    this.toggleHideCoursesDropdown()
    this.hideExpandedNavBar()
  }

  GetCoursesId = () => {
    let windowName = window.location.pathname.split("/")[1]
    

    if (windowName === "courses") {
      return "blueLink"
    } else {
      return ""
    }
  }

  LogIn = () => (
    <Nav className={this.state.expandedNavBar ? "logInCollapsed" : "logInExpanded"}>
      <Nav.Link className="mainNavLink" id="logInNavLink" as={Link} to="/login" onClick={() => {logEvent(aEventClick,"log_in",aGroupHeader,aTypeLink);this.hideExpandedNavBar()}}>Log In&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Nav.Link>
    </Nav>
  );

  LoggedIn = () => (
    <Nav>
      <Dropdown className={this.state.expandedNavBar ? "accountDropdownCollapsed" : "accountDropdown"} onClick={(event) => {this.AccountClicked(event);logEvent(aEventClick,"account",aGroupHeader,aTypeLink)}} show={window.innerWidth <= 991 ? undefined : this.state.showAccountDropdown} onMouseEnter={() => this.toggleShowAccountDropdown()} onMouseLeave={() => this.toggleHideAccountDropdown()}>
        <Dropdown.Toggle variant="" className="" id="coursesButton">
          <img id={window.location.pathname.split("/")[1] === "dashboard" || window.location.pathname.split("/")[1] === "profile" ? "blueFilter" : ""} className="accountImage" src={accountIcon} alt="account icon"></img>
        </Dropdown.Toggle>
        <Dropdown.Menu className={this.state.expandedNavBar ? "accountDropdownMenuCollapsed" : "accountDropdownMenu"}>
          <NavDropdown.Item as={Link} to="/dashboard" onClick={() => {logEvent(aEventClick,"dashboard",aGroupHeader,aTypeLink);this.DashboardClicked()}}>
            Dashboard
          </NavDropdown.Item>
          <NavDropdown.Divider/>
          <NavDropdown.Item as={Link} to="/profile" onClick={() => {logEvent(aEventClick,"profile",aGroupHeader,aTypeLink);this.ProfileClicked()}}>
            Profile
          </NavDropdown.Item>
          <NavDropdown.Divider/>
          <NavDropdown.Item id="signOutItem" onClick={() => {logEvent(aEventClick,"sign_out",aGroupHeader,aTypeButton);this.SignOutClicked()}}>
            <SignOutButton/>
          </NavDropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
    </Nav>
  );

  AboutDropdown = () => (
    <Dropdown className="coursesDropdown" onClick={(event) => {this.AboutClicked(event);logEvent(aEventClick,"about",aGroupHeader,aTypeLink)}} show={window.innerWidth <= 991 ? undefined : this.state.showAboutDropdown} onMouseEnter={() => this.toggleShowAboutDropdown()} onMouseLeave={() => this.toggleHideAboutDropdown()}>
      <Dropdown.Toggle variant="" className="" id="coursesButton">
        <Link to="" className="mainNavLink" id={window.location.pathname.split("/")[1] === "mission" || window.location.pathname.split("/")[1] === "admissions" ? "blueLink" : ""}>About</Link>
      </Dropdown.Toggle>
      <Dropdown.Menu className={this.state.expandedNavBar ? "aboutDropdownMenuCollapsed" : "aboutDropdownMenu"}>
        <NavDropdown.Item as={Link} to="/mission" onClick={() => {logEvent(aEventClick,"mission",aGroupHeader,aTypeLink);this.MissionClicked()}}>
          Our Mission
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/admissions" onClick={() => {logEvent(aEventClick,"admissions",aGroupHeader,aTypeLink);this.AdmissionsClicked()}}>
          Admissions
        </NavDropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  CoursesDropdown = () => (
    <Dropdown className="coursesDropdown" onClick={(event) => {this.CoursesClicked(event);logEvent(aEventClick,"courses",aGroupHeader,aTypeLink)}} show={window.innerWidth <= 991 ? undefined : this.state.showCoursesDropdown} onMouseEnter={() => this.toggleShowCoursesDropdown()} onMouseLeave={() => this.toggleHideCoursesDropdown()}>
      <Dropdown.Toggle variant="" className="" id="coursesButton">
        <Link className="mainNavLink" id={this.GetCoursesId()} to="">Programs</Link>
      </Dropdown.Toggle>
      <Dropdown.Menu className={this.state.expandedNavBar ? "coursesDropdownMenuCollapsed" : "coursesDropdownMenu"}>
        <NavDropdown.Item as={Link} to="/courses" onClick={() => {logEvent(aEventClick,"all_courses",aGroupHeader,aTypeLink);this.AllCoursesClicked()}}>
          All
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/courses/intro" onClick={() => {logEvent(aEventClick,"intro",aGroupHeader,aTypeLink);this.IntroClicked()}}>
          Intro
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/courses/python" onClick={() => {logEvent(aEventClick,"python",aGroupHeader,aTypeLink);this.PythonClicked()}}>
          Python
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/courses/ios" onClick={() => {logEvent(aEventClick,"ios",aGroupHeader,aTypeLink);this.IOSClicked()}}>
          iOS
        </NavDropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )

  render() {
    return (
      <div>
        <Navbar expanded={this.state.expandedNavBar} collapseOnSelect expand="lg" className="mainNav fixed-top ">
          <Navbar.Brand id="navbarLogo" className="" as={Link} to="/" onClick={() => {logEvent(aEventClick,"logo",aGroupHeader,aTypeLink);this.hideExpandedNavBar()}}>
            <div className="gridWrapper">
              <div className="grid-1-1">
                <img className="logoImage" src={mainLogo}  alt="vulcan learning institute logo" />
              </div>
              <div className="grid-2-1">
                <span className="logoText">Vulcan Learning Institute</span>
              </div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => this.toggleExpandedNavBar()} aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="">
            <Nav className="mx-auto">

              {this.AboutDropdown()}
              {this.CoursesDropdown()}
              <Nav.Link className="mainNavLink headerNavLink" as={Link} to="/faq" id={window.location.pathname.split("/")[1] === "faq" ? "blueLink" : ""} onClick={() => {logEvent(aEventClick,"faq",aGroupHeader,aTypeLink);this.hideExpandedNavBar()}}>FAQ</Nav.Link>
              <Nav.Link className="mainNavLink headerNavLink" as={Link} to="/blog" id={window.location.pathname.split("/")[1] === "blog" ? "blueLink" : ""} onClick={() => {logEvent(aEventClick,"blog",aGroupHeader,aTypeLink);this.hideExpandedNavBar()}}>Blog</Nav.Link>
            </Nav>
            <AuthUserContext.Consumer>
              {authUser =>
                authUser ? this.LoggedIn(): this.LogIn()
              }
            </AuthUserContext.Consumer>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const HeaderExport = withRouter(HeaderClass);

export default HeaderExport;