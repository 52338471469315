import React from 'react';
import '../Dashboard/dashboard.css';
 
import { withFirebase } from '../Firebase';
 
const SignOutButton = ({ firebase }) => (
  <button className="dashboardCancelButton" id="marginTop" type="button" onClick={firebase.signOut}>
    Sign Out
  </button>
);
 
export default withFirebase(SignOutButton);