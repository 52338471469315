
import firebase from "firebase/app";
import 'firebase/analytics';

export const aEventScreen = "page"
export const aEventClick = "click"
export const aTypeButton = "button"
export const aTypeLink = "link"
export const aTypeMount = "on_mount"
export const aSubGroup = "sub_group"
export const isExternal = "is_external"
export const socialMedia = "social_media"
export const aResources = "resources"
export const aNone = "none"
export const footerWaypoint = "footer_waypoint"

export const startCheckout = firebase.analytics.EventName.BEGIN_CHECKOUT
export const purchase = firebase.analytics.EventName.PURCHASE
export const refund = firebase.analytics.EventName.REFUND
export const aEventLogIn = firebase.analytics.EventName.LOGIN
export const aEventAccountCreated = firebase.analytics.EventName.SIGN_UP

export function logEvent(event,name,group,type,customKey1,customValue1,customKey2,customValue2) {
    firebase.analytics().logEvent(event,{ name: name, group: group, type: type, from:[window.location.pathname], [customKey1]:customValue1, [customKey2]:customValue2})
}
