import React, { Component, Suspense, lazy }  from "react";
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import packageJson from '../package.json';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { withFirebase } from './components/Firebase';
import { withAuthentication } from './components/AuthenticationSession';

import { app } from '../src/components/Firebase/firebase';
import 'firebase/analytics';
import RedditPixel from "react-reddit-pixel";

import ScrollToTop from "./components/ScrollToTop/scrollToTop.js";
import {Helmet} from "react-helmet";

import Header from "./components/Header/header.js";
import Footer from "./components/Footer/footer.js";

const Home = lazy(() => import('./components/Home/home.js'));
const Mission = lazy(() => import('./components/Mission/mission.js'));
const Courses = lazy(() => import('./components/Courses/courses.js'));
const Admissions = lazy(() => import('./components/Admissions/admissions.js'));
const LogIn = lazy(() => import('./components/LogIn/logIn.js'));
const CreateAccount = lazy(() => import('./components/CreateAccount/createAccount.js'));
const ResetPassword = lazy(() => import('./components/ResetPassword/resetPassword.js'));
const Dashboard = lazy(() => import('./components/Dashboard/dashboard.js'));
const VerifyEmail = lazy(() => import('./components/VerifyEmail/verifyEmail.js'));
const Checkout = lazy(() => import('./components/Checkout/checkout.js'));
const IOSCourse = lazy(() => import('./components/CoursePageIos/coursePageIos.js'));
const IntroCourse = lazy(() => import('./components/CoursePageIntro/coursePageIntro.js'));
const PythonCourse = lazy(() => import('./components/CoursePagePython/coursePagePython.js'));
const Profile = lazy(() => import('./components/Profile/profile.js'));
const ContactUs = lazy(() => import('./components/ContactUs/contactUs.js'));
const Policies = lazy(() => import('./components/Policies/policies.js'));
const PrivacyPolicy = lazy(() => import('./components/Policies/privacy.js'));
const Faqs = lazy(() => import('./components/Faqs/faqs.js'));
const NotFound = lazy(() => import('./components/NotFound/notFound.js'));

const Blog = lazy(() => import('./components/Blog/blog.js'));
const Blog1 = lazy(() => import('./components/Blog/blog1.js'));
const Blog2 = lazy(() => import('./components/Blog/blog2.js'));
const Blog3 = lazy(() => import('./components/Blog/blog3.js'));
const Blog4 = lazy(() => import('./components/Blog/blog4.js'));
const Blog5 = lazy(() => import('./components/Blog/blog5.js'));

const blog1Url = "/blog/best-free-resources-learn-coding"
const blog2Url = "/blog/are-coding-bootcamps-worth-it"
const blog3Url = "/blog/become-a-software-developer"
const blog4Url = "/blog/is-ios-development-worth-learning"
const blog5Url = "/blog/difference-front-end-back-end-full-stack-development"

class App extends Component {
      
      componentDidMount() {
            app.analytics().setAnalyticsCollectionEnabled(true)
            app.analytics().setUserProperties({app_version: packageJson.version});

            const options = {
                  debug: false, // set true to enable logs
                };
            RedditPixel.init("t2_9j60r7us", options);
      }

      render() {
            return (
                  <Router> 
                        <ScrollToTop>
                        <div className="App">
                              <Helmet>
                                    <meta charSet="utf-8" />
                                    <title>Online Coding Bootcamp | Learn Development Courses| Vulcan Learning Institute</title>
                              </Helmet>
                              <Suspense fallback={<Spinner/>}>
                                    <Header/> 
                                    <div className="mainSpacer"/>
                                    <Suspense fallback={<Spinner/>}>
                                    <Switch>
                                    <Route exact path="/" component={Home}/>  
                                    <Route path="/mission" component={Mission}/> 
                                    <Route exact path="/courses" component={Courses}/>
                                    <Route exact path="/courses/ios" component={IOSCourse}/>
                                    <Route exact path="/courses/intro" component={IntroCourse}/>
                                    <Route exact path="/courses/python" component={PythonCourse}/> 
                                    <Route path="/admissions" component={Admissions}/> 
                                    <Route path="/login" component={LogIn}/> 
                                    <Route path="/password" component={ResetPassword}/> 
                                    <Route path="/createaccount" component={CreateAccount}/> 
                                    <Route path="/dashboard" component={Dashboard}/> 
                                    <Route path="/verify" component={VerifyEmail}/> 
                                    <Route path="/checkout" component={Checkout}/>
                                    <Route path="/profile" component={Profile}/>
                                    <Route path="/contact" component={ContactUs}/>
                                    <Route path="/policies" component={Policies}/>
                                    <Route path="/privacy" component={PrivacyPolicy}/>
                                    <Route path="/faq" component={Faqs}/>
                                    <Route exact path="/blog" component={Blog}/>
                                    <Route exact path={blog1Url} component={Blog1}/>
                                    <Route exact path={blog2Url} component={Blog2}/>
                                    <Route exact path={blog3Url} component={Blog3}/>
                                    <Route exact path={blog4Url} component={Blog4}/>
                                    <Route exact path={blog5Url} component={Blog5}/>
                                    <Route path="*" component={NotFound}/>
                                    </Switch>
                                    </Suspense>
                                    <Footer/>
                              </Suspense>
                        </div>
                        </ScrollToTop>
                  </Router>
            );
      }
}

export default withFirebase(withAuthentication(App));
