import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    // faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from "react-router-dom";
import { Waypoint } from 'react-waypoint';

import './footer.css';
import {logEvent, aEventClick, aTypeLink, aSubGroup, isExternal, socialMedia, footerWaypoint} from '../Analytics/analytics'

const aGroupFooter = "footer"

const FooterContainer = () => (
    <div className="footerContainer">
      <Footer/>
    </div>
)


const Footer = () => {
    return (
        <footer className="footer">
          <Waypoint onEnter={() => logEvent(footerWaypoint)}/>
            <div className="footerSubContainer">
                <div className="item1">
                  <Container className="footerTitle">
                    <span className="blueColor boldFont"> Education for Everyone</span>
                  </Container>
                </div>

                <div className="item2">
                  <Container>
                    <Row>
                    <Col className="footerLinkCol">
                      <Link className="footerLink" to="/contact" onClick={() => logEvent(aEventClick,"contact",aGroupFooter,aTypeLink)}>Contact</Link>
                    </Col>
                    <Col className="footerLinkCol">
                      <Link className="footerLink" to="/policies" onClick={() => logEvent(aEventClick,"policies",aGroupFooter,aTypeLink)}>Policies</Link>
                    </Col>
                    <Col className="footerLinkCol">
                      <Link className="footerLink" to="/privacy" onClick={() => logEvent(aEventClick,"privacy",aGroupFooter,aTypeLink)}>Privacy</Link>
                    </Col>
                    </Row>
                  </Container>
                </div>

                <div className="item2">
                    <Container>

                      <FontAwesomeIcon icon={faCopyright} />{" "}
                      <span style={{ paddingLeft: 5 }}>
                          {new Date().getFullYear()}
                      </span>
                      <span style={{ paddingLeft: 5 }}>
                          Vulcan Learning Institute
                      </span>
                      
                    </Container>
                    
                </div>
                <a
                    href="https://twitter.com/vulcaninstitute"
                    target="_blank"
                    rel="noreferrer"
                    className="item3"
                    onClick={() => logEvent(aEventClick,"twitter",aGroupFooter,aTypeLink, aSubGroup, socialMedia, isExternal, true)}
                >
                    <FontAwesomeIcon icon={faTwitter} />
                </a>
                {/* <a
                    href="https://www.facebook.com/Vulcan-Learning-Institute-101122995886800"
                    target="_blank"
                    rel="noreferrer"
                    className="item3"
                    onClick={() => logEvent(aEventClick,"facebook",aGroupFooter,aTypeLink, aSubGroup, socialMedia, isExternal, true)}
                >
                    <FontAwesomeIcon icon={faFacebook} />
                </a> */}
            </div>
        </footer>
    );
};

export default FooterContainer;

